import store from '@/store';

export default class PermissionService {

    public is(role: string): boolean {
        let can = false;

        this.getRoles().forEach((r) => {
            can = r.name === role;
        });

        return can;
    }

    public can(permission: string): boolean {
        if (this.is('Super Admin')) {
            return true;
        }

        let can = false;

        this.getRoles().forEach((r) => {
            can = r.permissions.some((p) => {
                 return p === permission;
            });
        });

        return can;
    }

    private getRoles() {
        return (store.state.authenticatedUser)
            ? store.state.authenticatedUser.roles
            : [];
    }

}
