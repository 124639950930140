
    import SettingsService from '@/services/settings.service';
    import store from '@/store';

    export default {
      async mounted() {
        const html = document.documentElement;

        await (new SettingsService())
          .getAll()
          .then(
            (response) => {
              store.commit(
                {
                  type: 'setModules',
                  modules: response.data.modules,
                },
              )

              this.isLoading = false
            },
          )

        html.setAttribute(
          'lang',
          this.$i18n.locale,
        );

        document.addEventListener(
          'authenticated',
          () => {
            this.authenticated = true;
            this.$forceUpdate();
          },
        );

        if (this.$store.state.authenticatedUser) {
          this.authenticated = true;
        }

      },

      data() {
        return {
          isMenuOpen: false,
          isLoading: true,
          authenticated: false,
          appName: process.env.VUE_APP_NAME,
          strategy: process.env.VUE_APP_INTERACTION_DETAILS,
          updateDate: process.env.VUE_APP_UPDATE_DATE,
          version: process.env.PACKAGE_VERSION,
        };
      },

      methods: {
        hideMenu() {
          this.isMenuOpen = false;
        },
        toggleMenu() {
          this.isMenuOpen = !this.isMenuOpen;
        },
      },
    }
