
  export default {
    name: 'RepTargetWidget',

    props: [
      'targets',
    ],

    methods: {
      getLabelName(labels) {
        if (labels.length < 1) {
            return '';
        }

        return `(${labels[0].name})`
      },
    },

    computed: {
      totalPlanned(): number {
        return this.targets.reduce(
          (total, target) => {
              return total += target.planned
          },
          0,
        )
      },

      totalCovered(): number {
        return this.targets.reduce(
          (total, target) => {
            return total += target.covered
          },
          0,
        )
      },

      totalPercentage(): number {
          return (this.totalCovered * 100) / this.totalPlanned;
      },
    },
  }
