
  import StatisticsService from '@/services/statistics.service'

  const statisticsService = new StatisticsService()

  export default {
    name: 'RepEveningStatsWidget',

    props: {
      id: Number,
    },

    data() {
      return {
        eveningStats: [],
      }
    },

    beforeMount() {
      statisticsService
        .eveningStats(this.id, this.cycleId)
        .then(response => this.eveningStats = response.data)
    },

    computed: {
        hasEveningStats() {
          if (!this.eveningStats)
            return false

          return this.eveningStats.congres || this.eveningStats.meeting
        },

        totalCongress() {
          return this.eveningStats.congres ? this.eveningStats.congres : 0
        },

        totalMeeting() {
          return this.eveningStats.meeting ? this.eveningStats.meeting : 0
        },

        eveningStateTotal() {
          return this.totalCongress + this.totalMeeting
        },
    },

    watch: {
      cycleId() {
        statisticsService
          .eveningStats(this.id, this.cycleId)
          .then((response) => this.eveningStats = response.data)
      },
    },
  }
