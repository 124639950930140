
  import StatisticsService from '@/services/statistics.service'

  const statisticsService = new StatisticsService()

  export default {
    name: 'TypesPerTargetWidget',

    props: {
      id: Number,
      cycleId: Number,
    },

    data() {
      return {
        targets: [],
      }
    },

    beforeMount() {
      statisticsService
        .typesPerTarget(
          this.id,
          this.cycleId,
        )
        .then((response) => {
          this.targets = response.data;
        })
    },

    watch: {
      cycleId() {
        statisticsService
          .typesPerTarget(
            this.id,
            this.cycleId,
          )
          .then((response) => {
            this.targets = response.data;
          })
      },
    },
  }
