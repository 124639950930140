
  export default {
    name: 'Card',
    computed: {
      hasHeaderSlot(): boolean {
        return !!this.$slots.header
      },
      hasFooterSlot(): boolean {
        return !!this.$slots.footer
      },
    },
  }
