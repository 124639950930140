import axios, { AxiosResponse } from 'axios'

export default class LocationService {
  public getAll(): Promise<AxiosResponse> {
    return axios.get('/api/v1/locations');
  }

  public getById(id: number): Promise<AxiosResponse> {
    return axios.get(`/api/v1/locations/${id}`)
  }
}