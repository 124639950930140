import axios from 'axios';

export default class BrickService {

    public getAll(repId?: number) {
        return axios.get('/api/v1/bricks/', {
            params: {
                repId,
            },
        });
    }

}
