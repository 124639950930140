import axios, { AxiosResponse } from 'axios'

export class IssuedMaterialService
{
  public getAll(
    limit: number,
    offset: number,
    query?: string,
    fromDate?,
    toDate?,
    repId?,
    rayonId?,
    brickId?,
    productId?,
    tierId?,
    userId?,
    labelId?,
    clientId?,
  ): Promise<AxiosResponse>
  {
    return axios.get(
      '/api/v1/issued-materials',
      {
        params: {
          limit,
          offset,
          query,
          fromDate,
          toDate,
          repId,
          rayonId,
          brickId,
          productId,
          tierId,
          userId,
          labelId,
          clientId,
        },
      },
    );
  }

  public export(
    query?: string,
    fromDate?,
    toDate?,
    repId?,
    rayonId?,
    brickId?,
    productId?,
    tierId?,
    userId?,
    labelId?,
  ): Promise<AxiosResponse>
  {
    return axios.get(
      '/api/v1/issued-materials/export',
      {
        responseType: 'blob',
        params: {
          query,
          fromDate,
          toDate,
          repId,
          rayonId,
          brickId,
          productId,
          tierId,
          userId,
          labelId,
        },
      },
    );
  }
}
