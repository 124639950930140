import axios, { AxiosResponse } from 'axios';

export default class SettingsService
{
    public getAll(): Promise<AxiosResponse>
    {
        return axios.get('/api/v1/settings')
    }

    public getMaterialLimit(): Promise<AxiosResponse>
    {
        return axios.get('/api/v1/material-limit')
    }
}
