import Vue from 'vue'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import vueDebounce from 'vue-debounce'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import en from '@/locales/en.json'
import nl from '@/locales/nl.json'
import fr from '@/locales/fr.json'
import VueI18n from 'vue-i18n'
import { ServiceFactory } from '@/services/ServiceFactory'
import { can, Permission } from '@/composables/useGuard'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(vueDebounce)

Vue.mixin({
  methods: {
    navigateTo(route) {
      return this.$router.push(route);
    },

    can(permission: Permission) {
      return can(permission);
    },

    is(role) {
      return ServiceFactory.createPermissionService().is(role);
    },

    enumerateStrings(args: [string]): string {
      let enumeration = '';

      if (args.length < 1) {
        return enumeration;
      }

      args.forEach((arg) => enumeration += `${arg}, `);

      return enumeration.substring(0, enumeration.length - 2);
    },

    hasModule(name: string): boolean {
      switch (name) {
        case 'optIn': return store.state.modules.optIn
        case 'cycle': return store.state.modules.cycle
        case 'tier': return store.state.modules.tier
        case 'target': return store.state.modules.target
        case 'rayon': return store.state.modules.rayon
        case 'activity': return store.state.modules.activity
        default: throw new Error(`No module configured for "${name}"`)
      }
    },
  },
})

axios.interceptors.request.use(
  (config) => {
    const token = store.state.accessToken

    if (token)
      config.headers.Authorization = `Bearer ${token}`

    return config
  },
  null,
)

axios.interceptors.response.use(
  null,
  (error) => {
    if (401 === error.response.status) {
      store.commit(
        {
          type: 'setAuthenticatedUser',
          authenticatedUser: false,
        },
      );

      store.commit(
        {
          type: 'setAccessToken',
          accessToken: '',
        },
      );

      location.reload();
    }

    if (403 === error.response.status) {
      location.href = '/403';
    }
  },
);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

caches.keys().then((cacheNames) => {
  cacheNames.forEach((cacheName) => {
    caches.delete(cacheName).then();
  });
});

interface Translations {
  generic
  auth
  menu
  clients
  client
  interactions
  products
  createProduct
  updateProduct
  reps
  rep
  account
  forbidden
  notFound
}

const messagesValidation = {
  nl,
  en,
  fr,
} as { nl: Translations, en: Translations, fr: Translations };



const fallbackLocale = process.env.VUE_APP_DEFAULT_LANGUAGE;
let locale = fallbackLocale

if (store && store.state && store.state.authenticatedUser && store.state?.authenticatedUser.hasOwnProperty('language')) {
  locale = store.state.authenticatedUser.language
}

// const locale = store.state?.authenticatedUser.hasOwnProperty('language')
//   ? store.state.authenticatedUser.language
//   : fallbackLocale;

const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages: {
    nl,
    en,
    fr,
  },
})

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "spotoninteract.nl", /^\//],
      }),
    ],
    tracesSampleRate: 0,
  })
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
