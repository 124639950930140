import axios from 'axios';

export default class StatisticsService {

    public activitySumPerRep(
        cycleId,
    ) {
        return axios.get(
            '/api/v1/activity-sum-per-rep',
            {
                params: {
                    cycleId,
                },
            },
        );
    }

    public interactionTypePerRep(
        cycleId,
    ) {
        return axios.get(
            '/api/v1/interaction-type-sum-per-rep',
            {
                params: {
                    cycleId,
                },
            },
        );
    }

    public typesPerTarget(
        userId,
        cycleId,
    ) {
        return axios.get(
            '/api/v1/statistics/types-per-target',
            {
                params: {
                    userId,
                    cycleId,
                },
            },
        );
    }

    public interactionAverage(
        userId,
        cycleId,
    ) {
        return axios.get(
            '/api/v1/statistics/interaction-average',
            {
                params: {
                    userId,
                    cycleId,
                },
            },
        );
    }

    public countPerType(
        userId: number,
        cycleId: number,
    ) {
        return axios.get(
            '/api/v1/statistics/interaction-types',
            {
                params: {
                    userId,
                    cycleId,
                },
            },
        );
    }

    public eveningStats(
        userId: number,
        cycleId: number,
    ) {
        return axios.get(
            '/api/v1/statistics/evening-stats',
            {
                params: {
                    userId,
                    cycleId,
                },
            },
        );
    }

}
