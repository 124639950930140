
  import Card from '@/components/Card.vue'
  import RepTargetWidget from '@/components/RepTargetWidget.vue'
  import RepEveningStatsWidget from '@/components/RepEveningStatsWidget.vue'
  import RepCoverageWidget from '@/components/RepCoverageWidget.vue'
  import InteractionAverageWidget from '@/components/InteractionAverageWidget.vue'
  import TypesPerTargetWidget from '@/components/TypesPerTargetWidget.vue'
  import CountPerInteractionTypeWidget from '@/components/CountPerInteractionTypeWidget.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'

  export default {
    name: 'RepDashboard',

    props: {
      id: Number,
      selectedCycleId: Number,
    },

    components: {
      Card,
      RepTargetWidget,
      RepEveningStatsWidget,
      RepCoverageWidget,
      InteractionAverageWidget,
      CountPerInteractionTypeWidget,
      TypesPerTargetWidget,
    },

    async beforeMount() {
      await this.updateTargets()
    },

    data() {
      return {
        targets: [],
        strategy: process.env.VUE_APP_INTERACTION_DETAILS,
      }
    },

    methods: {
      updateTargets() {
        ServiceFactory
          .createTargetService()
          .getByUserId(this.id, this.selectedCycleId)
          .then((response) => this.targets = response.data)
      },
      getLabelName(labels) {
        if (labels.length < 1)
          return ''

        return `(${labels[0].name})`
      },
    },

    computed: {
      userHasTargets(): boolean {
        return this.targets.length > 0
      },
    },

    watch: {
      selectedCycleId() {
        this.updateTargets()
      },
    },
  }
